import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReservationComponent } from './reservation/reservation.component';

const routes: Routes = [{
	path: 'a/:id_agent',
	component: ReservationComponent,
},{
	path: '',
	component: ReservationComponent,
},{
	path: '**',
	redirectTo: '',
	pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
